<template>
    <div>
        <v-img
          class="mx-auto mt-12"
          src="@/assets/logo.png"
          max-width="40"
          max-height="40"
          contain>
        </v-img> 
        <h4 class="mt-1 text-center">AmIsraeLive.com</h4>

        <v-row class="mx-auto mt-4"
            justify="center">

            <v-btn
                to="/privacypolicy" 
                text 
                x-small>
                Privacy Policy
            </v-btn>
        </v-row>
        <br/>

    </div>    
</template>


<script>

export default {
    
}

</script>